import React from 'react';
import * as d3 from "d3";
import * as topojson from "topojson-client";
import "./styles/Visual4.css";


class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.updateGraph = this.updateGraph.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.tmpCeil = 5;
  }
  
  roundColour(t,r) {
    return Math.floor((t+0.00001)/r)*r;
  }

  componentDidMount () {
      this.updateGraph();
  }
  handleMouse = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.style.display = 'flex';
    tooltip.style.top = event.y+'px';
    tooltip.style.left = event.x+'px';
    let temp = document.createElement('p');
    temp.textContent = `${event.target.dataset.area}, ${event.target.dataset.state}: ${event.target.dataset.education}%`;
    tooltip.appendChild(temp);
    tooltip.setAttribute('data-education',event.target.dataset.education);
    tooltip.style.marginLeft = '-'+Math.ceil(tooltip.offsetWidth/2) + 'px';
    tooltip.style.marginTop = '-'   + parseInt( tooltip.offsetHeight + 20).toString() + 'px';
    event.target.setAttribute("stroke",'red')
    event.target.setAttribute("stroke-width",'2')
  }
  handleMouseOut = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = '';
    tooltip.setAttribute('style','display: none');
    tooltip.removeAttribute('data-education');
    event.target.removeAttribute('stroke');
  }

  updateGraph = () => {
    const graphCont = document.getElementById('graph');
    while (graphCont.firstChild) {
      graphCont.removeChild(graphCont.lastChild);
    }
    const aspect = 0.625;
    // const w = Math.min(960, window.innerWidth - 20);
    const w = 960;
    const h = Math.floor(w*aspect); 
    const legendWidth = 5
    const data = this.props.data;
    const geoData = this.props.geo;
    
    let bMin = 100;
    let bMax = 0;
    const iterator = data[Symbol.iterator]();
    for (const item of iterator) {
      bMin = bMin >= item[1]['bachelorsOrHigher']?item[1]['bachelorsOrHigher']:bMin;
      bMax = bMax <= item[1]['bachelorsOrHigher']?item[1]['bachelorsOrHigher']:bMax;
    }
    const cScale = [bMin,bMax]
    const color = d3.scaleSequential([bMax,bMin], d3.interpolateRdYlBu).clamp(true);

    const tScale = d3.scaleLinear().domain([0,this.roundColour(bMax+this.tmpCeil,this.tmpCeil)  ]).range([0, this.roundColour(bMax+this.tmpCeil,this.tmpCeil)*legendWidth]);
    
    const svg = d3.select('#graph').append('svg').attr("viewBox", [0, 0, w, h]).attr("width", w).attr("height", h);
    const map = svg.append( "g" ).attr( "id", "map" );
    const projection = null;
    const geoGeo = topojson.feature(geoData, geoData.objects.counties);
    map.selectAll("path")
    .data(geoGeo.features)
    .join("path")
    .attr("fill", d => {
      let area = data.get(d.id);
      return color(this.roundColour(area.bachelorsOrHigher,this.tmpCeil))
    })
    .attr("class",'county')
    .attr("data-fips", d=>d.id)
    .attr("data-education", d => {
      let area = data.get(d.id);
      return area.bachelorsOrHigher;
    })
    .attr("data-state", d => {
      let area = data.get(d.id);  
      return area.state;
    })
    .attr("data-area", d => {
      let area = data.get(d.id);
      return area.area_name;
    })
    .attr("stroke-linejoin", "round")
    .attr("d", d3.geoPath(projection))
    .on('mouseover',this.handleMouse)
    .on('mouseout',this.handleMouseOut);

    const legend =svg.append("g");
    legend.attr('id','legend');
    const legendPic = legend.append('g');
    for (let i = cScale[0];i <= cScale[1];i=i+0.1) {
      let k = i > cScale[0]?Math.round((i + Number.EPSILON) * 100) / 100:0
      let t = this.roundColour(i,this.tmpCeil);
      if((t === k && k > 0) || i === cScale[0]) {
      legendPic.append("rect")
        .attr("x", 450+k*legendWidth)
        .attr("y", 20)
        .attr("width",tScale(legendWidth))
        .attr("height",20)
        .attr('fill',color(t))
      }
    }
    legend.append("g") 
    .attr("transform", `translate(450,40)`)
    .call(d3.axisBottom(tScale).tickFormat(t=>`${t}%`));
  }
  render() {
    return (
    <div id="graph">

    </div>
    );
  }
}

class Visual4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geo:{},
      data: [],
      loaded: false,
      error: false
    };
  }

  componentDidMount() {
    fetch("./counties.json")
    .then(res => res.json())
    .then(
      (geo) => {
        fetch("./for_user_education.json")
        .then(out => out.json())
        .then(
          (data) =>{
            let dataMap = new Map();
            for(let d of data) {
              dataMap.set(d.fips,{'state':d.state,'area_name':d.area_name,'bachelorsOrHigher':d.bachelorsOrHigher});
            }
            this.setState({
              geo: geo,
              data:dataMap,
              loaded: true,
              error: false
            })
          },
          (error) => {
            this.setState({
              loaded: true,
              error: error
            })
          }
        )
      },
      (error) => {
        this.setState({
          loaded: true,
          error: error
        })
      }
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <main>
          <h1> Please wait some time.... </h1>
        </main>
      );
    } else if(this.state.error) {
      return (
        <main>
          <h1> Error while loading data: </h1>
          <div>{this.state.error}</div>
        </main>
      );
    } else {
      return (
        <main id="visual-4">
        <div id="title">United States Educational Attainment  </div>
        <div id="description"> Percentage of adults age 25 and older with a bachelor's degree or higher (2010-2014)</div>
        <Graph 
          data={this.state.data}
          geo={this.state.geo}
        />
        <div id='source'>Source: <a href='https://www.ers.usda.gov/data-products/county-level-data-sets/county-level-data-sets-download-data/'>USDA Economic Research Service</a></div>
        <div id='tooltip'></div>
      </main>
      );
    }
  }
}

export default Visual4;