import React from 'react';
import * as d3 from "d3";
import "./styles/Visual3.css";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.updateGraph = this.updateGraph.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.months = {
      '1':'January',
      '2':'February',
      '3':'March',
      '4':'April',
      '5':'May',
      '6':'June',
      '7':'July',
      '8':'August',
      '9':'September',
     '10':'October',
     '11':'November',
     '12':'December'
    }
    this.tmpCeil = 0.5;
  }
  
  roundTemp(t,r) {
    return Math.ceil(t/r)*r;
  }

  componentDidMount () {
      this.updateGraph();
  }
  handleMouse = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.style.display = 'flex';
    tooltip.style.top = event.y+'px';
    tooltip.style.left = event.x+'px';
    if(event.target.dataset.year) {
      let date = document.createElement('p');
      date.textContent = event.target.dataset.year + ', ' + this.months[(parseInt(event.target.dataset.month) + 1).toString()];
      tooltip.appendChild(date);
      tooltip.setAttribute('data-year',event.target.dataset.year);
    } else {
      console.log(event.x,event.y)
    }
    let temp = document.createElement('p');
    let divg = document.createElement('p');
    temp.textContent = (parseFloat(event.target.dataset.temp) + parseFloat(this.props.baseTemp)).toFixed(2)+'°C';
    divg.textContent = parseFloat(event.target.dataset.temp).toFixed(2)+'°C';
    tooltip.appendChild(temp);
    tooltip.appendChild(divg);
    tooltip.style.marginLeft = '-'+Math.ceil(tooltip.offsetWidth/2) + 'px';
    tooltip.style.marginTop = '-'   + parseInt( tooltip.offsetHeight + 20).toString() + 'px';
    event.target.setAttribute("stroke",'black')
  }
  handleMouseOut = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = '';
    tooltip.setAttribute('style','display: none');
    event.target.removeAttribute('stroke');
  }

  updateGraph = () => {
    const graphCont = document.getElementById('graph');
    while (graphCont.firstChild) {
      graphCont.removeChild(graphCont.lastChild);
    }
    const aspect =0.38;
    const paddingTop = 0;
    const paddingBottom = 120;
    const paddingLeft = 80;
    const paddingRight = 10;
    const w = Math.min(1400, window.innerWidth - 20);
    const h = Math.max(Math.floor(w*aspect),530); 
    const nTicks = w < 500 ? 8 : 10;
    const tWidth = w < 700 ? 307 : 614;
    const tRect = w < 700 ? 25 : 50;
    const tShift = w < 700 ? 43 : 84;
    const data = this.props.data
    
    const yearScale = d3.extent(data, d => d.year);
    const varScale = d3.extent(data, d=>d.variance+this.props.baseTemp);
    const color = d3.scaleSequential(d3.extent(data, d=>(d.variance+this.props.baseTemp)).reverse(), d3.interpolateRdYlBu).clamp(true);

    const xScale = d3.scaleLinear().domain(yearScale).range([paddingLeft, w - paddingRight]);
    const yScale =  d3.scaleBand().domain(Object.values(this.months)).range([paddingTop,h - paddingBottom])
    
    const hScale = d3.scaleLinear().domain([0,12]).range([0,h - paddingTop - paddingBottom]);
    const rectW = (xScale(yearScale[1]) - xScale(yearScale[0])) / (yearScale[1] - yearScale[0]);
    const tScale = d3.scaleLinear().domain(varScale).range([0, tWidth]);

    const svg = d3.select('#graph').append('svg');
    svg.attr("width", w).attr("height", h);
    svg.append("g") 
    .attr("transform", `translate(0,${h - paddingBottom})`)
    .attr('id','x-axis')
    .call(d3.axisBottom(xScale).tickFormat(d3.format("d")).ticks(nTicks));
    svg.append("g").attr("transform", `translate(${paddingLeft},0)`)
    .attr('id','y-axis')
    .call(d3.axisLeft(yScale));
    svg.selectAll("rect").data(data).enter().append("rect")
      .attr("x", d => xScale(d.year))
      .attr("y", d => yScale(this.months[d.month]))
      .attr("width",rectW)
      .attr("height", hScale(1))
      .attr('class','cell')
      .attr('fill',d => color(this.roundTemp(d.variance+this.props.baseTemp,this.tmpCeil)))
      .attr('data-month',d => d.month - 1)
      .attr('data-year',d => d.year)
      .attr('data-temp',d => d.variance)
      .on('mouseover',this.handleMouse)
      .on('mouseout',this.handleMouseOut);

    const legend =svg.append("g");
    legend.attr('id','legend');
    const legendPic = legend.append('g');
    for (let i = varScale[0];i <= varScale[1];i=i+0.01) {
      let t = this.roundTemp(i,this.tmpCeil);
      legendPic.append("rect")
        .attr("x", d => i*tRect)
        .attr("y", d => 440)
        .attr("width",5)
        .attr("height",20)
        .attr('fill',color(t))
        .attr('data-month',1)
        .attr('data-year',2000)
        .attr('data-temp',this.props.baseTemp)
    }
    legend.append("g") 
    .attr("transform", `translate(${tShift},460)`)
    .call(d3.axisBottom(tScale).tickFormat(d3.format("d")).ticks(nTicks));
  }
  render() {
    return (
      <div id="graph">
      </div>
    );
  }
}

class Visual3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      baseTemp:'',
      loaded: false,
      error: false
    };
  }

  componentDidMount() {
    fetch("./global-temperature.json")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          data: result.monthlyVariance,
          baseTemp:result.baseTemperature,
          loaded: true,
          error: false
        })
      },
      (error) => {
        this.setState({
          loaded: true,
          error: error
        })
      }
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <main id="visual-3">
          <h1> Please wait some time.... </h1>
        </main>
      );
    } else if(this.state.error) {
      return (
        <main id="visual-3">
          <h1> Error while loading data: </h1>
          <div>{this.state.error}</div>
        </main>
      );
    } else {
      return (
        <main id="visual-3">
        <h1>Monthly Global Land-Surface Temperature</h1>
        <div id="description">1753 - 2015: base temperature {this.state.baseTemp}</div>
        <Graph 
          data={this.state.data}
          baseTemp={this.state.baseTemp}
        />
        <div id='tooltip'></div>
      </main>
      );
    }
  }
}
export default Visual3;