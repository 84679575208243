import React from 'react';
import * as d3 from "d3";
import "./styles/Visual2.css"

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.updateGraph = this.updateGraph.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }
  
  componentDidMount () {
      this.updateGraph();
  }
  handleMouse = (event) => {
    const formatTime = d3.timeFormat('%M:%S');
    let tooltip = document.getElementById('tooltip');
    tooltip.style.display = 'flex';
    tooltip.style.top = event.y+'px';
    tooltip.style.left = event.x+'px';
    let rider = document.createElement('p');
    let ride = document.createElement('p');
    let riderData = event.target.dataset.name + ': ' + event.target.dataset.country;
    let rideData = 'Year: ' + event.target.dataset.xvalue + ', Time: ' + formatTime(new Date(event.target.dataset.yvalue));
    rider.textContent = riderData;
    ride.textContent = rideData;
    tooltip.appendChild(rider);
    tooltip.appendChild(ride);
    tooltip.setAttribute('data-year',event.target.dataset.xvalue);
    if(event.target.dataset.doping !== '') {
      let doping = document.createElement('p');
      doping.setAttribute('id','tooltip-doping')
      doping.textContent = event.target.dataset.doping;
      tooltip.appendChild(doping);
    }
    
  }
  handleMouseOut = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = '';
    tooltip.setAttribute('style','display: none');
  }

  updateGraph = () => {
    const graphCont = document.getElementById('graph');
    while (graphCont.firstChild) {
      graphCont.removeChild(graphCont.lastChild);
    }
    const parseTime = d3.timeParse('%M:%S')
    const parseYear = d3.timeParse('%Y')
    const aspect = 0.5
    const paddingTop = 30;
    const paddingBottom = 40;
    const paddingLeft = 50;
    const paddingRight = 20;
    const w = Math.min(900, window.innerWidth - 20);
    const h = Math.floor(w*aspect); 
    const legendRect = Math.min(20, Math.floor(h*0.05));
    const yEvery = h < 300 ? 30 : 15;
    const paddingX = 1;
    const paddingY = 15;
    const data = this.props.data;   
    let timeScale = d3.extent(data, d => parseTime(d.Time));
    let yearScale = d3.extent(data, d => parseYear(d.Year));
    yearScale[0].setUTCFullYear(yearScale[0].getUTCFullYear() - paddingX);
    yearScale[1].setUTCFullYear(yearScale[1].getUTCFullYear() + paddingX);
    timeScale[0].setUTCSeconds(timeScale[0].getUTCSeconds() - paddingY);
    timeScale[1].setUTCSeconds(timeScale[1].getUTCSeconds() + paddingY);
    [timeScale[0],timeScale[1]] = [timeScale[1],timeScale[0]]
    const xScale = d3.scaleTime().domain(yearScale).range([paddingLeft, w - paddingRight]);
    const yScale = d3.scaleTime().domain(timeScale).range([h - paddingBottom, paddingTop]);
    
    const svg = d3.select('#graph').append('svg');
    svg.attr("width", w).attr("height", h);
    svg.append("g")
    .attr("transform", `translate(0,${h - paddingBottom})`)
    .attr('id','x-axis')
    .call(d3.axisBottom(xScale));
    svg.append("g").attr("transform", `translate(${paddingLeft},0)`)
    .attr('id','y-axis')
    .call(d3.axisLeft(yScale).tickFormat(d3.timeFormat("%M:%S")).tickArguments([d3.utcSecond.every(yEvery)]));
    svg.selectAll("circle").data(data).enter().append("circle")
    .attr("r", h < 300 ? 4 : 6)
    .attr("cx", d => xScale(parseYear(d.Year)))
    .attr("cy", d => yScale(parseTime(d.Time)))
    .attr('fill',d=>(d.Doping === ''?'rgba(31, 118, 180, 0.6)':'rgba(255, 127, 14, 0.6)'))
    .attr('class','dot')
    .attr('stroke','black')
    .attr('data-xvalue', d => d.Year)
    .attr('data-yvalue', d => parseTime(d.Time))
    .attr('data-name', d => d.Name)
    .attr('data-country', d => d.Nationality)
    .attr('data-doping', d => d.Doping)
    .on('mouseover', this.handleMouse)
    .on('mouseout', this.handleMouseOut);

    const legend =svg.append("g");
    legend.attr('id','legend');

    const legendDoping = legend.append('g');
    legendDoping.append('rect')
    .attr("width", legendRect)
    .attr("height", legendRect)
    .attr("x", w - 1.1*paddingLeft)
    .attr("y", yScale(parseTime('38:00')))
    .attr('fill','rgba(255, 127, 14, 0.6)');
    legendDoping.append('text')
    .attr("class", "legend")
    .style("text-anchor", 'end')
    .attr("x", w - 1.1*paddingLeft - 5)
    .attr("y", yScale(parseTime('38:09')))
    .text('Riders with doping allegations');
    
    const legendClear = legend.append('g');
    legendClear.append('rect')
    .attr("width", legendRect)
    .attr("height", legendRect)
    .attr("x", w - 1.1*paddingLeft)
    .attr("y", yScale(parseTime('37:45')))
    .attr('fill','rgba(31, 118, 180, 0.6)');
    legendClear.append('text')
    .attr("class", "legend")
    .style("text-anchor", 'end')
    .attr("x", w - 1.1*paddingLeft - 5)
    .attr("y", yScale(parseTime('37:54')))
    .text('No doping allegations');
  }
  render() {
    return (
      <div id="graph"></div>
    );
  }
}
class Visual2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      error: false
    };
  }

  componentDidMount() {
    fetch("https://raw.githubusercontent.com/freeCodeCamp/ProjectReferenceData/master/cyclist-data.json")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          data: result,
          loaded: true,
          error: false
        })
      },
      (error) => {
        this.setState({
          loaded: true,
          error: error
        })
      }
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <h1> Pleses wait some time.... </h1>
        </div>
      );
    } else if(this.state.error) {
      return (
        <div>
          <h1> Error while loading data: </h1>
          <div>{this.state.error}</div>
        </div>
      );
    } else {
      return (
        <main id="visual-2">
        <div id="title">
          <h1>Doping in Professional Bicycle Racing</h1>
          <p>35 Fastest times up Alpe d'Huez</p>
        </div>
        <Graph 
          data={this.state.data}
        />
        <div id='tooltip'></div>
      </main>
      );
    }
  }
}

export default Visual2;