import { useForm } from 'react-hook-form';

const MinerForm = ({field,nxmax,nymax,handler}) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  let nbmax  = nxmax * nymax;


  return(
    <form id="sf" onSubmit={handleSubmit(handler)}>
        <label htmlFor="nx">{errors.nx && <span className='error-message'>{errors.nx.message} </span>}X size</label>
        <input type="number" name="nx" id="nx" defaultValue={nxmax} placeholder='X size of field' form="sf"
          {...register("nx",{
            required: {value: true, message: 'This field is required'},
            min: {value: 1, message: "Should be more than 1"},
            max: {value: nxmax, message: "Should be less than " + nxmax}
          })} 
          />
        <label htmlFor="ny">{errors.ny && <span className='error-message'>{errors.ny.message} </span>}Y size</label>
        <input type="number" name="ny" id="ny" defaultValue={nymax} placeholder='Y size of field' form="sf"
          {...register("ny",{
            required: {value: true, message: 'This field is required'},
            min: {value: 1, message: "Should be more than 1"},
            max: {value: nymax, message: "Should be less than " + nymax}
          })} 
          />
        <label htmlFor="nbomb">{errors.nbomb && <span className='error-message'>{errors.nbomb.message} </span>}Bomb</label>
        <input type="number" name="nbomb" id="nbomb" defaultValue={field.nbomb} placeholder='Number of bombs' form="sf"
          {...register("nbomb",{
            required: {value: true, message: 'This field is required'},
            min: {value: 1, message: "Should be more than 1"},
            max: {value: nbmax, message: "Should be less than " + nbmax}
          })} />
        <label htmlFor="button"></label>
        <input type="submit" name="button" value="Submit" form="sf"/>
      </form>
  );
}

export default MinerForm;