
// const RandomQuotes = () => {
//   return(
//   <main>
//     <h1>Random Quotes</h1>
//   </main>
//   );
// };



import React from 'react';
import "./styles/RandomQuotes.css"

class RandomQuotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_id: -1,
      quote: {
        text:'default',
        author: 'default'
      }
    }
    
    this.quotes = [
        { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
        { text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
        { text: "Don't count the days, make the days count.", author: "Muhammad Ali" },
        { text: "You miss 100% of the shots you don't take.", author: "Wayne Gretzky" },
        { text: "Learn from yesterday, live for today, hope for tomorrow.", author: "Albert Einstein" },
        { text: "The harder you work for something, the greater you'll feel when you achieve it.", author: "Vince Lombardi" },
        { text: "The best revenge is massive success.", author: "Frank Sinatra" },
        { text: "Life is either a daring adventure or nothing at all.", author: "Helen Keller" },
        { text: "Success is not final, failure is not fatal: It is the courage to continue that counts.", author: "Winston Churchill" },
        { text: "The only thing we have to fear is fear itself.", author: "Franklin D. Roosevelt" },
        { text: "The future belongs to those who prepare for it today.", author: "Malcolm X" },
        { text: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt" },
        { text: "It does not matter how slowly you go, as long as you do not stop.", author: "Confucius" },
        { text: "In three words I can sum up everything I've learned about life: it goes on.", author: "Robert Frost" },
        { text: "The best way to predict the future is to create it.", author: "Peter Drucker" },
        { text: "Success is stumbling from failure to failure with no loss of enthusiasm.", author: "Winston Churchill" },
        { text: "I have not failed. I've just found 10,000 ways that won't work.", author: "Thomas A. Edison" },
        { text: "To succeed in life, you need two things: ignorance and confidence.", author: "Mark Twain" },
        { text: "It always seems impossible until it's done.", author: "Nelson Mandela" },
        { text: "The greatest glory in living lies not in never falling, but in rising every time we fall.", author: "Nelson Mandela"},
        { text: "The secret of getting ahead is getting started.", author: "Mark Twain" },
        { text: "Life is 10% what happens to us and 90% how we react to it.", author: "Charles R. Swindoll" },
        { text: "The only thing standing between you and your dream is the will to try and the belief that it is actually possible.", author: "Joel Brown" },
        { text: "The only person you are destined to become is the person you decide to be.", author: "Ralph Waldo Emerson" },
        { text: "The road to success and the road to failure are almost exactly the same.", author: "Colin R. Davis" },
        { text: "If you want to achieve greatness stop asking for permission.", author: "Anonymous" },
        { text: "Opportunities don't happen. You create them.", author: "Chris Grosser" },
        { text: "The best revenge is massive success.", author: "Frank Sinatra" },
        { text: "The way to get started is to quit talking and begin doing.", author: "Walt Disney" },
        { text: "Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.", author: "Albert Schweitzer" },
        { text: "Your time is limited, don't waste it living someone else's life.", author: "Steve Jobs" },
        { text: "The future depends on what you do today.", author: "Mahatma Gandhi" },
        { text: "Success is not in what you have, but who you are.", author: "Bo Bennett" },
        { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
        { text: "The secret of getting ahead is getting started.", author: "Mark Twain" },
        { text: "You are never too old to set another goal or to dream a new dream.", author: "C.S. Lewis" },
        { text: "Don't watch the clock; do what it does. Keep going.", author: "Sam Levenson" },
        { text: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
        { text: "Success is walking from failure to failure with no loss of enthusiasm.", author: "Winston Churchill" },
        { text: "The best way to predict the future is to invent it.", author: "Alan Kay"},
        { text: "The only true wisdom is in knowing you know nothing.", author: "Socrates"},
        { text: "The only thing necessary for the triumph of evil is for good men to do nothing.", author: "Edmund Burke" },
        { text: "The journey of a thousand miles begins with one step.", author: "Lao Tzu" },
        { text: "The best way out is always through.", author: "Robert Frost" },
        { text: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt"},
        { text: "The only thing worse than being blind is having sight but no vision.", author: "Helen Keller"},
        { text: "If you want to live a happy life, tie it to a goal, not to people or things.", author: "Albert Einstein"},
        { text: "I can't change the direction of the wind, but I can adjust my sails to always reach my destination.", author: "Jimmy Dean"},
        { text: "The best and most beautiful things in the world cannot be seen or even touched - they must be felt with the heart.", author: "Helen Keller"},
        { text: "I have a dream that one day this nation will rise up and live out the true meaning of its creed: \"We hold these truths to be self-evident, that all men are created equal.\"", author: "Martin Luther King Jr."},
        { text: "The only easy day was yesterday.", author: "Navy SEALs" }
    ];
    this.setNewQuote = this.setNewQuote.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setNewQuote();
    }, 10);
  }
  setNewQuote() {
    const [id, quote] = this.getRandom(this.quotes,this.state.current_id);
    this.setState(()=>({
      current_id: id,
      quote: quote
    }));
    let root = document.querySelector(':root');
    let color =  Math.floor(Math.random()*16777215).toString(16);
    let hash = '#'+'0'.repeat(6 - color.length);
    root.style.setProperty('--quotebox-color', hash+color);
  }
  getRandom(arr,curr = -1) {
    let i = Math.floor(Math.random()*arr.length)
    while(i === curr) {
      i = Math.floor(Math.random()*arr.length)
    }
    return [i,arr[i]];
  }

  render() {
    let twit = 'https://twitter.com/intent/tweet?hashtags=quotes&text='+encodeURIComponent('"'+this.state.quote.text+'" '+this.state.quote.author)
    return (
      <main>
        <div id='quote-container'>
          <div id='quote-box'>
            <div id="text">{this.state.quote.text}</div>
            <div id="author">{this.state.quote.author}</div>
            <div id="buttons">
              <a href={twit} id="twitter">' '</a>
              <button type='button' id="new_quote" onClick={this.setNewQuote}>New Quote</button>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default RandomQuotes;