import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter, faFreeCodeCamp} from '@fortawesome/free-brands-svg-icons'


const Contacts = () => {
  let contactList = [
    {
      key:1,
      type:'phone',
      icon: faPhone,
      href:'tel:+818074915594'
    },
    {
      key:2,
      type:'email',
      icon: faEnvelope,
      href:'mailto:alex.n.bezborodov@gmail.com'
    },
    {
      key:-2,
      type:'facebook',
      icon: faFacebookF,
      href:'https://www.facebook.com/wholename'
    },
    {
      key:-3,
      type:'twitter',
      icon: faXTwitter,
      href:'https://twitter.com/pionerity'
    },
    {
      key:30,
      type:'linkedin',
      icon: faLinkedinIn,
      href:'https://www.linkedin.com/in/alexander-bezborodov/'
    },
    {
      key:40,
      type:'instagram',
      icon: faInstagram,
      href:'https://instagram.com/pionerity'
    },
    {
      key:50,
      type:'freecodecamp',
      icon: faFreeCodeCamp,
      href:'https://www.freecodecamp.org/pionerity'
    }
  ];
  let contactString = contactList.map(contact =>
    contact.key > 0 ? 
      (<a href={contact.href} key={contact.key}>
        <FontAwesomeIcon icon={contact.icon} />
      </a>) : ('')
  );

  return(
    <section id="contacts-section">
      <header>Contacts</header>
      <div className="social-icons">
          {contactString}
        </div>
    </section>
  );
}

export default Contacts;