import * as React from 'react';
import * as d3 from "d3";
import "./styles/Visual1.css"

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.updateGraph = this.updateGraph.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }
  
  componentDidMount () {
      this.updateGraph();
  }
  handleMouse = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.style.display = 'flex';
    tooltip.style.top = event.y+'px';
    tooltip.style.left = event.x+'px';
    let date = document.createElement('p');
    let gdp = document.createElement('p');
    let qyear = 'Q'+Math.ceil((new Date(event.target.dataset.date).getMonth() + 1) / 3).toString() + ' ' + new Date(event.target.dataset.date).getFullYear();
    date.textContent = qyear;
    gdp.textContent = '$'+event.target.dataset.gdp+' Billion';
    tooltip.appendChild(date);
    tooltip.appendChild(gdp);
    tooltip.setAttribute('data-date',event.target.dataset.date);
  }
  handleMouseOut = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = '';
    tooltip.setAttribute('style','display: none');
  }

  updateGraph = () => {
    const graphCont = document.getElementById('graph');
    while (graphCont.firstChild) {
      graphCont.removeChild(graphCont.lastChild);
    }
    const aspect = 0.5
    const paddingTop = 30;
    const paddingBottom = 20;
    const paddingLeft = 50;
    const paddingRight = 10;
    const w = Math.min(900, window.innerWidth - 20);
    const h = Math.floor(w*aspect);
    const xMin = this.props.from;
    const xMax = this.props.to;
    const data = this.props.data
    const rectW = (w - paddingLeft - paddingRight)/data.length;
    const xScale = d3.scaleTime().domain([new Date(xMin), new Date(xMax),]).range([paddingLeft, w - paddingRight]);
    const yScale = d3.scaleLinear().domain([0, d3.max(data, (d) => d[1])]).range([h - paddingBottom, paddingTop]);
    const hScale = d3.scaleLinear().domain([0, d3.max(data, (d) => d[1])]).range([0,h - paddingTop - paddingBottom]);
    const svg = d3.select('#graph').append('svg');
    svg.attr("width", w).attr("height", h);
    svg.append("g")
    .attr("transform", `translate(0,${h - paddingBottom})`)
    .attr('id','x-axis')
    .call(d3.axisBottom(xScale));
    svg.append("g").attr("transform", `translate(${paddingLeft},0)`)
    .attr('id','y-axis')
    .call(d3.axisLeft(yScale));
    svg.selectAll("rect").data(data).enter().append("rect")
      .attr("x", (d, i) => (xScale(new Date(d[0]))))
      .attr("y", (d, i) => (yScale(d[1])))
      .attr("width", rectW)
      .attr("height", (d, i) => (hScale(d[1])))
      .attr('class','bar')
      .attr('fill','#33bb5c')
      .attr('data-date',(d, i) => d[0])
      .attr('data-gdp',(d, i) => d[1])
      .on('mouseover',this.handleMouse)
      .on('mouseout',this.handleMouseOut)
  }
  render() {
    return (
      <div id="graph"></div>
    );
  }
}

class Visual1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title:'',
      desc:'',
      from:'',
      to:'',
      loaded: false,
      error: false
    };
  }

  componentDidMount() {
    fetch("./GDP-data-1.json")
    .then(res => res.json())
    .then(
      (result) => {
        // console.log(result)
        this.setState({
          data: result.data,
          title:result.name,
          desc:result.description,
          from:result.from_date,
          to:result.to_date,
          loaded: true,
          error: false
        })
      },
      (error) => {
        this.setState({
          loaded: true,
          error: error
        })
      }
    )
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div>
          <h1> Pleses wait some time.... </h1>
        </div>
      );
    } else if(this.state.error) {
      return (
        <div>
          <h1> Error while loading data: </h1>
          <div>{this.state.error}</div>
        </div>
      );
    } else {
      const desc = this.state.desc

      return (
      <main id="visual-1">
        <div id="title">{this.state.title}</div>
        <Graph 
          data={this.state.data}
          title={this.state.title}
          from={this.state.from}
          to={this.state.to}
        />
        <div id="description">{desc}</div>
        <div id='tooltip'></div>
      </main>
      );
    }
  }
}

export default Visual1;