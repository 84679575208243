import Welcome from "./Welcome";
import Projects from "./Projects";
import Contacts from "./Contacts";

export default function MainContent() {
  return(
    <main>
      <Welcome />
      <Projects />
      <Contacts />
    </main>
  );
}