const Navigation = ()=>{

  let pages = [
    {id:0,title:'Welcome',href:'/#welcome-section'},
    {id:1,title:'Projects',href:'/#projects'},
    {id:2,title:'Contacts',href:'/#contacts-section'},
  ];
  let navList = pages.map(page => <li className="nav-link" key={page.id}><a href={page.href}>{page.title}</a></li>);
  return(
    <nav id="navbar">
      <ul>{navList}</ul>
    </nav>
  );
}

export default Navigation;