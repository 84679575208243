import React from 'react';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import './styles/App.css';
import Header from './Header';
import Footer from './Footer';
import MainContent from './MainContent';
import RandomQuotes from "./RandomQuotes";
import Miner from "./Miner";
import MarketCRM from "./MarketCRM";
import Markdown from "./Markdown";
import Drum from "./Drum";
import Calculator from "./Calculator";
import Timer from "./Timer";
import Visual1 from "./Visual1";
import Visual2 from "./Visual2";
import Visual3 from "./Visual3";
import Visual4 from "./Visual4";
import Visual5 from "./Visual5";
import ErrorPage from "./ErrorPage";

function App() {
const routes = createBrowserRouter([
    {
        path: "/",
        element: <MainContent />,
        errorElement: <ErrorPage />
    },
    {path: "/random_quotes",                element: <RandomQuotes />},
    {path: "/sapper",                       element: <Miner />},
    {path: "/marketcrm",                    element: <MarketCRM />},
    {path: "/markdown",                     element: <Markdown />},
    {path: "/drum",                         element: <Drum />},
    {path: "/calc",                         element: <Calculator />},
    {path: "/timer",                        element: <Timer />},
    {path: "/visual-1",                     element: <Visual1 />},
    {path: "/visual-2",                     element: <Visual2 />},
    {path: "/visual-3",                     element: <Visual3 />},
    {path: "/visual-4",                     element: <Visual4 />},
    {path: "/visual-5",                     element: <Visual5 />}
    ]);
  return (
    <React.StrictMode>
        <Header />
        <RouterProvider router={routes} />
        <Footer />
    </React.StrictMode>
  );
}

export default App;
