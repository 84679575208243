import React from "react";
import "./styles/Timer.css";

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.handleSetter = this.handleSetter.bind(this);
  }
  componentDidUpdate(prevProps) {
    if(this.props !== prevProps) {
      this.checkBtnInactive();
    }
  }
  handleSetter(event) {
    if(this.props.now !== 'start') {
      const [section, action ] = event.target.id.split("-");
      let curr = this.props[section];
      if(action === 'increment') {
        curr = curr < 60?curr+1:curr;
      } else {
        curr = curr > 1?curr-1:curr;
      }
      let newState = {};
      newState['now'] = 'reset';
      [newState.session] = [this.props.session];
      [newState.break] = [this.props.break];
      [newState[section]] = [curr];
      this.checkBtnInactive();
      this.props.handler(newState);
    }
  };

  checkBtnInactive() {
    let btns = document.getElementsByClassName('controll-button');
    Array.prototype.forEach.call(btns, (btn)=>{
      let disable = this.props.now === 'start';
      let [section, action] = btn.id.split('-');
      if(action === 'decrement') {
        disable = disable || this.props[section] < 2;
      } else {
        disable = disable || this.props[section] > 59;
      }
      if(disable) {
        btn.classList.add('inactive');
      } else {
        if( btn.classList.contains('inactive')) {
          btn.classList.remove('inactive');
        }
      }
    });
  }

  render() {
    return (
      <section id='controls'>
        <section id='session' className="time-setter">
          <header id='session-label'>Session</header>
          <div className='setter-btns'>
            <button id='session-decrement' className="controll-button decrement" onClick={this.handleSetter}></button>
            <div id='session-length' className="setter-nums">{this.props.session}</div>
            <button id='session-increment' className="controll-button increment" onClick={this.handleSetter}></button>
          </div>
        </section>
        <section id='break'  className="time-setter">
          <header id='break-label'>Break</header>
          <div className='setter-btns'>
            <button id='break-decrement' className="controll-button decrement" onClick={this.handleSetter}></button>
            <div id='break-length' className="setter-nums">{this.props.break}</div>
            <button id='break-increment' className="controll-button increment" onClick={this.handleSetter}></button>
          </div>
        </section>
      </section>
    );
  }
}

function minsToMs(m,s = 0) {
  return (m*60 + s)*1000;
}
function msToMins(ms) {
  if(ms === 60*60000) return '60:00';
  let s = Math.floor((ms / 1000) % 60);
  let m =  Math.floor((ms / 1000 / 60) % 60);
  return String(m).padStart(2,'0')+':'+String(s).padStart(2,'0');
}

class TimerEngine extends React.Component {
  constructor(props) {
    super(props);
    this.leftTime = minsToMs(this.props.session);
    this.timer = msToMins(this.leftTime);
    this.current = 'session';
    this.now = 'stop';
    this.tokey = null;
    this.endTime = 0;
    this.handlerTimer = this.handlerTimer.bind(this);
  }
  
  logstates() {
    console.log("Log Cirrent state of Timer: ");
    console.log("now: ",this.now);
    console.log("current: ",this.current);
    console.log("timer: ",this.timer);
    console.log("leftTime: ",this.leftTime);
    console.log("endTime: ",this.endTime);
    console.log("tokey: ",this.tokey);
  }

  updateTimer() {
    document.getElementById('time-left').innerHTML = this.timer;
    document.getElementById('timer-label').innerHTML = this.current;
  }
  componentDidMount(){
    this.leftTime = minsToMs(this.props.session);
    this.timer = msToMins(this.leftTime);
    this.current = 'session';
    this.now = 'stop';
    this.updateTimer();
  }

  componentDidUpdate(prevProps) {
    if(this.props.session !== prevProps.session || this.props.break !== prevProps.break ) {
      this.leftTime = minsToMs(this.props.session);
      this.timer = msToMins(this.leftTime);
      this.current = 'session';
      this.now = 'stop';
      this.updateTimer();
    }
  }

  handlerTimer = (event => {
    let action = event.target.id;
    if(action === 'reset') {
      this.stopClock()
      let audio = document.getElementById('beep');
      audio.pause();
      audio.currentTime = 0;
      this.leftTime = minsToMs(25);
      this.timer = '25:00';
      this.current = 'session';
      this.updateTimer();
    } else {
      if(this.now === 'stop') {
        this.startClock();
      } else {
        this.stopClock();
      }
    }
    this.props.handler(action);
  });

  changeClock() {
    this.stopClock();
    this.current = this.current === 'session'?'break':'session';
    this.leftTime = minsToMs(this.props[this.current]);
    this.timer = msToMins(this.leftTime);
    this.updateTimer();
    this.startClock();
  }

  stopClock() {
    this.now = 'stop';
    if(this.tokey) clearInterval(this.tokey);
    let btn = document.getElementById('start_stop');
    if( btn.classList.contains('start')) {
      btn.classList.remove('start');
    }
    this.leftTime = this.endTime - Date.now();
  }
  startClock() {
    this.now = 'start';
    this.endTime = Date.now() + this.leftTime;
    this.timer = msToMins(this.leftTime);
    document.getElementById('start_stop').classList.add('start');
    this.updateTimer();
    this.tokey = setInterval(() => {
      let left = (this.endTime - Date.now());
      if(left <= 500) left = 0;
      if( msToMins(left) !== this.timer) {
        this.timer =  msToMins(left);
        this.updateTimer();
      }
      if(left < 1500) {
        let audio = document.getElementById('beep');
        audio.play();
      }
      if(left <= 500) this.changeClock(); 
    },500)
  }

  render() {
    return (
      <div id='timer-main'>
        <div id='timer-label'>{this.current}</div>
        <div id='time-left'>{this.timer}</div>
        <div id='timer-buttons'>
          <button id='start_stop' onClick={this.handlerTimer}></button>
          <button id='reset' onClick={this.handlerTimer}></button>
          <audio id='beep' preload="true" src="./audio/beep.wav"></audio>
        </div>
      </div>
    );
  }
}

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      now:'reset',
      session:25,
      break:5
    }
    this.handleTimer = this.handleTimer.bind(this);
  }
  handleSetter = (data => {
    this.setState(data);
  })

  handleTimer(action) {
    let [curr] = [this.state.now];
    switch(action) {
      case 'start_stop':
        if(curr === 'start') {
          this.setState({now:'stop'});
        } else {
          this.setState({now:'start'});
        }
      break;
      case 'reset':
        this.setState({
          now:'reset',
          session:25,
          break:5
        });
      break;
      case 'change':
        this.setState({
          now:'start'
        });
      break;
      default:
        console.log('unknown action type: ',action)
      break;
    }
  };
 
  render() {
    return (
      <main id='timer'>
        <div id='pomodoro'>
          <Controls 
              session={this.state.session}
              break={this.state.break}
              now={this.state.now}
              handler={this.handleSetter}
            />
          <TimerEngine 
              session={this.state.session}
              break={this.state.break}
              handler={this.handleTimer}
            />
        </div>
      </main>
    );
  }
}

export default Timer;