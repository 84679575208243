import React, { useState, memo, useRef, useEffect } from "react";
import MinerForm from "./MinerForm";
import "./styles/Miner.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBomb, faBurst, faFlag } from "@fortawesome/free-solid-svg-icons";

function getRandom(arr, n) {
  let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
  while (n--) {
      let x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

const Miner = () => {
  // states
  let cellSize = window.innerWidth <= 520 ? 22.4 : 28;
  let nymax = Math.max(Math.floor((window.innerHeight - 320) / cellSize) - 2,1);
  let nxmax = Math.max(Math.min(Math.floor((window.innerWidth) / cellSize) - 2, Math.floor(nymax * 2)),1);
  let nbdef = Math.max(Math.floor(nxmax * nymax / 5),1);
  const [field,setField] = useState({
    'nx':nxmax,
    'ny':nymax,
    'nbomb':nbdef
  });
  const[counter,setCounter] = useState({
    'cell':field.nx*field.ny,
    'bomb':field.nbomb
  });
  const[gameState, setGameState] = useState('start');
  const[cells,setCells] = useState({});

  function updateCells(field=field, status='new', newCells={}) {
    let newGameState = 'play';
    if(status === 'new') {
      for(let iy = 1; iy <= field.ny; iy++) {
        for(let ix = 1; ix <= field.nx; ix++) {
          let id=`c_${ix}_${iy}`;
          newCells[id] = {
            'id':id,
            'status':'close',
            'bomb':false,
            'near':0,
            'nbhr':[]
          };
        }
      }
      let bombIds = getRandom(Object.keys(newCells),field.nbomb);
      for(let id of bombIds) {
        newCells[id]['bomb'] = true;
      }
      for(let ix = 1; ix <= field.nx; ix++) {
        for(let iy = 1; iy <= field.ny; iy++) {
          let id=`c_${ix}_${iy}`;
          for(let cx = Math.max(ix - 1, 1); cx <= Math.min(ix + 1, field.nx); cx++) {
            for(let cy = Math.max(iy - 1, 1); cy <= Math.min(iy + 1, field.ny); cy++) {
              let nbId = `c_${cx}_${cy}`
              if(nbId !== id) {
                newCells[id]['nbhr'].push(nbId);
                if(newCells[nbId]['bomb'] && !newCells[id]['bomb']) {
                  newCells[id]['near'] = newCells[id]['near'] + 1;
                }
              }
            }
          }
        }
      }
    } else if(status === 'boom') {
      newGameState = 'fail'
      for(let [i,v] of Object.entries(newCells)) {
        if(v.bomb && v.status === 'close') {
          newCells[i].status = 'bomb';
        }
      }
    }
    let cellLeft = Object.entries(newCells).reduce((a,v)=>v[1]['status']==='close'?a+1:a,0);
    let bombOpen = counter.bomb;
    if(newGameState !== 'fail') bombOpen = field.nbomb - Object.entries(newCells).reduce((a,v)=>v[1]['status']==='flag'?a+1:a,0);
    if(cellLeft === 0 && newGameState === 'play') newGameState = 'win';
    setCounter({
      'cell':+cellLeft,
      'bomb':+bombOpen
    });
    setGameState(newGameState);
    setCells(newCells);
  };

  const clickCell = (id,newCells) => {
    let status = 'open';
    if(newCells[id].bomb) {
      newCells[id].status = 'boom';
      status = 'boom';
    } else {
      if(newCells[id].status === 'close') {
        if(newCells[id].near === 0) {
          newCells[id].status = 'open';
          let ids = newCells[id].nbhr.filter(i=>newCells[i].status === 'close');
          for(let i of ids) {
            clickCell(i,newCells);
          }
        } else {
          newCells[id].status = 'neighbour';
        }
      }
    }
    updateCells(field,status,newCells);
  }

  const handleLeftClick = (event,id) => {
    if(gameState === 'play') {
      if(cells[id].status === 'close') {
        clickCell(id,{...cells});
      } else if(cells[id].status === 'neighbour') {
        let flags = cells[id].nbhr.reduce((a,i)=>{return cells[i].status === 'flag'?a+1:a},0);
        console.log('id, flags = ',id, flags);
        if(flags === cells[id].near) {
          let ids = cells[id].nbhr.filter(i=>cells[i].status === 'close');
          console.log('id, ids = ',id, ids);
          for(let id of ids) {
            clickCell(id,{...cells});
          }
        }
      }
    }
  }

  const handleRightClick = (event,id) => {
    event.preventDefault();
    if(gameState === 'play') {
      let newCells = {...cells};
      if(cells[id].status === 'flag') {
        newCells[id].status = 'close';
      } else {
        newCells[id].status = 'flag';
      }
      updateCells(field,'flag',newCells);
    }
  }

  const handleFormSubmit = (data) => {
    setField(data);
    setCounter({
      'cell':field.nx*field.ny,
      'bomb':field.nbomb
    });
    setGameState('play');
    updateCells(data);
  };
  
  let title = '';
  switch(gameState) {
    case 'play':
    case 'start':
       title = 'Sapper';
      break;
    case 'win':
      title = 'Win!';
      break;
    case 'fail':
      title = 'Fail';
      break;
    default:
      title = 'Miner';
      break;
  }

  const ref = useRef(null);
  useEffect(() => {
    nymax = Math.floor((window.innerHeight - document.getElementById('root').offsetHeight) / cellSize);
    nxmax = ref.current ? Math.floor(ref.current.offsetWidth / cellSize) - 2 : 1;
    nbdef = Math.floor(nxmax * nymax / 5);
    setField({
      'nx':nxmax,
      'ny':nymax,
      'nbomb':nbdef
    })
  }, [ref.current]);
  console.log(nymax, nxmax );
  let fieldStyle={"gridTemplateColumns": `repeat(${field.nx}, 2.8rem)`};  
  return(
    <main id="miner" ref={ref}>
        {gameState === 'play' ? 
        <header id="miner-header">
          <MinerCounter field={field} counter={counter}/>
        </header>
         : 
        <header id="miner-header">
          <h1 className={gameState}>{title}</h1>
          <MinerForm field={field} nxmax={nxmax} nymax={nymax} handler={handleFormSubmit}/>
        </header>
      }
      <div id="miner-field" style={fieldStyle}>
        {Object.entries(cells).map(c=><MinerCells key={c[1].id} cell={c[1]} handlerLeft={handleLeftClick} handlerRight={handleRightClick}/>)}
      </div>
    </main>
  );
};

const MinerCounter = ({field,counter}) => {
  return(
    <div id="miner-counters">
      <div className="counter-title">Cells open:</div><div className="counter-num">{field.nx*field.ny - counter.cell}</div>
      <div className="counter-title">Cells left:</div><div className="counter-num">{counter.cell}</div>
      <div className="counter-title">Bombs mark:</div><div className="counter-num">{field.nbomb - counter.bomb}</div>
      <div className="counter-title">Bombs left:</div><div className="counter-num">{counter.bomb}</div>
    </div>
  );
};

const MinerCells = memo(({cell,handlerLeft, handlerRight})=> {
  let inner = '';
  switch(cell.status) {
    case 'neighbour':
      inner = cell.near;
    break;
    case 'bomb':
      inner = <FontAwesomeIcon icon={faBomb} />;
    break;
    case 'flag':
      inner = <FontAwesomeIcon icon={faFlag} />;
    break;
    case 'boom':
      inner = <FontAwesomeIcon icon={faBurst} />;
    break;
    default:
      inner = '';
    break;
  }
  return(
    <div 
      className={`cell ${cell.status}`} 
      {...cell.status !== 'flag' ? {onClick: (e)=>handlerLeft(e,cell.id)} : '' }
      {...cell.status === 'close' || cell.status === 'flag' ? {onContextMenu: (e)=>handlerRight(e,cell.id)} : '' }
      data-near={cell.near} id={cell.id}>
      {inner}
    </div>
  );
});

export default Miner;