import React from 'react';
import * as math from 'mathjs';
import './styles/Calculator.css';

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current:'0'
    }
    this.readkey = this.readKey.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keydown',this.readKey);
  }
  readKey(event) {
    event.preventDefault();
    const key = event.key;
    const keys = {
      0:'zero', 1:'one', 2:'two', 3:'three', 4:'four', 5:'five', 6:'six', 7:'seven', 8:'eight', 9:'nine','.':'decimal',
      '-':'subtract', '+':'add', '/':'divide', '*':'multiply',
      '(':'lpar', ')':'rpar',
      '=':'equals','Enter':'equals',
      'Backspace':'back','Escape':'clear'
    }
    if(keys[key]) {
      const evt = new Event('click',{bubbles:true});
      const button = document.getElementById(keys[key]);
      button.classList.add('clicked');
      setTimeout(() => {button.classList.remove('clicked')}, "100");
      button.dispatchEvent(evt);
    }
  }
  handleClick(event) {
    const key = event.target.id;
    const keys = {
      'zero':'0','one':'1','two':'2','three':'3','four':'4','five':'5','six':'6','seven':'7','eight':'8','nine':'9','decimal':'.',
      'subtract':'-','add':'+','divide':'/','multiply':'*',
      'lpar':'(','rpar':')',
      'equals':'=','back':'back','clear':'clear'
    }
    if(keys[key]) {
      this.props.handler(keys[key]);
    }
  }
  render() {
    return(
      <div id="controls">
        <button id="zero" className='button btn-num' onClick={this.handleClick}>0</button>
        <button id="one" className='button btn-num' onClick={this.handleClick}>1</button>
        <button id="two" className='button btn-num' onClick={this.handleClick}>2</button>
        <button id="three" className='button btn-num' onClick={this.handleClick}>3</button>
        <button id="four" className='button btn-num' onClick={this.handleClick}>4</button>
        <button id="five" className='button btn-num' onClick={this.handleClick}>5</button>
        <button id="six" className='button btn-num' onClick={this.handleClick}>6</button>
        <button id="seven" className='button btn-num' onClick={this.handleClick}>7</button>
        <button id="eight" className='button btn-num' onClick={this.handleClick}>8</button>
        <button id="nine" className='button btn-num' onClick={this.handleClick}>9</button>
        <button id="decimal" className='button btn-num' onClick={this.handleClick}>.</button>
        <button id="clear" className='button btn-clear' onClick={this.handleClick}>AC</button>
        <button id="back" className='button btn-act' onClick={this.handleClick}>&larr;</button>
        <button id="add" className='button btn-act' onClick={this.handleClick}>+</button>
        <button id="subtract" className='button btn-act' onClick={this.handleClick}>-</button>
        <button id="multiply" className='button btn-act' onClick={this.handleClick}>&times;</button>
        <button id="divide" className='button btn-act' onClick={this.handleClick}>&divide;</button>
        <button id="equals" className='button btn-eq' onClick={this.handleClick}>=</button>
      </div>
    );
  }
}
class Calculator extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          expression:'',
          start:''
      }
      this.handleButtons = this.handleButtons.bind(this)
  }
  keys = {
  }
  handleButtons = (key => {
      let start = this.state.start.slice(0);;
      let expr = this.state.expression.slice(0);
      let op = ['+','-','*','/'];
      switch(key) {
        case 'back':
          if (this.state.expression.length > 0) {
            expr = this.state.expression.slice(0,-1);
          }
          break;
        case 'clear':
          expr = '';
          start = ''
          break;
        case '=':
          if(start.length > 0) {
            expr = start;
          } else {
            while(op.includes(expr.slice(-1))) {
              expr = expr.slice(0,-1);
            }
            start = expr.length > 0?math.evaluate(expr).toString():'0';
            expr = start;
          }
          break;
        case '*':
        case '/':
        case '+':
          while(op.includes(expr.slice(-1))) {
            expr = expr.slice(0,-1);
          }
        case '-':
          if(start.length === 0) {
            expr = expr + key;
          } else {
            expr = start.slice(0) + key;
            start='';
          }
          break;
        case '.':
          const reg1 = new RegExp(/([0-9]{1,}$)/);
          const reg2 = new RegExp(/([0-9]{0,}\.[0-9]{0,}$)/);
          if(reg2.test(expr)) {
            
          } else if(! reg1.test(expr)) {
            expr = expr + '0' + key;
            start = '';
          } else {
            start = '';
            expr = expr + key;
          }
          break;
        default:
          if(expr === '0') {
            expr = '';
          } else {
            if(start !== '') {
              expr = '';
            }
            start = '';
            expr = expr + key;
          }
          break;
      }
      this.setState({
        expression:expr,
        start:start
      })
  });
  render() {
      return(
        <main id='calculator'>
          <div id='calc'>
            <div id="display">{this.state.expression !== ''?this.state.expression:'0'}</div>
            <Controls handler={this.handleButtons} />
          </div>
        </main>
      );
  }
}

export default Calculator;