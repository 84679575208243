
import React from 'react';
import "./styles/Drum.css"

class Controls extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      volume: this.props.volume,
      power: this.props.power,
      sound: this.props.sound
      };
      this.handleVolume = this.handleVolume.bind(this);
      this.togglePower = this.togglePower.bind(this);
  }

  handleVolume(event) {
      let vol = event.target.value / 100;
      this.setState({
          volume: vol
      });
      this.props.handlerVolume(vol);
  }

  handlePower(event) {
      this.setState({
          volume: event.target.value
      });
      this.props.handlerPower(event.target.value);
  }

  togglePower(event) {
      const powerstate = this.state.power==='on'?'off':'on';
      this.setState({
          power: powerstate
      });
      this.props.handlerPower(powerstate);
  }

  render() {
      return(
      <div id='controls'>
          <div className='row'>
              <div id='power-switch' className={this.state.power} onClick={this.togglePower}>{this.state.power}</div>
              <div id='display'>{this.props.sound}</div>
          </div>
          <input type='range' min='0' max='100' step='1' id='vol' value={this.state.volume * 100} onChange={this.handleVolume} />
      </div>
      );
  }
}

class DrumMachine extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrum = this.handleDrum.bind(this);
  }

  componentDidMount() {
      document.addEventListener('keypress',this.keyListener);
  }div

  keyListener = (event) => {
      const id = ("b-"+event.key).toUpperCase();
      console.log(id);    
      const button = document.getElementById(id);
      if(button) {
          let clc = new Event('click',{bubbles: true});
          button.dispatchEvent(clc);
          button.classList.add('clicked');
          setTimeout(() => {button.classList.remove('clicked')}, "100");
      }
  }

  handleDrum = (event => {
      if(this.props.power === 'on') {
          const audio = event.target.firstChild;
          this.props.handlerDrum(audio.id);
          audio.volume = this.props.volume;
          audio.play();
      }
  });
  render() {
    return(
      <div id='pads'>
          <button className='drum-pad' accessKey='q' onClick={this.handleDrum} id="B-Q">
              <audio id="Q" className="clip" src="https://s3.amazonaws.com/freecodecamp/drums/Heater-1.mp3" />
              Q
          </button>
          <button className='drum-pad' accessKey='w' onClick={this.handleDrum} id="B-W">
              <audio id="W" className="clip" src="./audio/Heater-2.mp3" />
              W
          </button>
          <button className='drum-pad' accessKey='e' onClick={this.handleDrum} id="B-E">
              <audio id="E" className="clip" src="./audio/Heater-3.mp3" />
              E
          </button>
          <button className='drum-pad' accessKey='a' onClick={this.handleDrum} id="B-A">
              <audio id="A" className="clip" src="./audio/Heater-4_1.mp3" />
              A
          </button>
          <button className='drum-pad' accessKey='s' onClick={this.handleDrum} id="B-S">
              <audio id="S" className="clip" src="./audio/Heater-6.mp3" />
              S
          </button>
          <button className='drum-pad' accessKey='d' onClick={this.handleDrum} id="B-D">
              <audio id="D" className="clip" src="./audio/Dsc_Oh.mp3" />
              D
          </button>
          <button className='drum-pad' accessKey='z' onClick={this.handleDrum} id="B-Z">
              <audio id="Z" className="clip" src="./audio/Kick_n_Hat.mp3" />
              Z
          </button>
          <button className='drum-pad' accessKey='x' onClick={this.handleDrum} id="B-X">
              <audio id="X" className="clip" src="./audio/RP4_KICK_1.mp3" />
              X
          </button>
          <button className='drum-pad' accessKey='c' onClick={this.handleDrum} id="B-C">
              <audio id="C" className="clip" src="./audio/Cev_H2.mp3" />
              C
          </button>
      </div>
    );
  }
}

class Drum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sound: '',
      volume: 0.5,
      power: 'on'
    }
  }
  
  handlerDrum = (val => {
    const sounds = {
      Q:'Heater 1',
      W:'Heater 2',
      E:'Heater 3',
      A:'Heater 4',
      S:'Clap',
      D:'Open-HH',
      Z:'Kick-n\'-Hat',
      X:'Kick',
      C:'Closed-HH'
    }
    this.setState({
      sound: sounds[val]
    });
  });
  
  handlerVolume = (val => {
    this.setState({
      volume: val
    })
  });
  
  handlerPower = (val => {
    this.setState({
      power: val
    });
    if(val === 'off') {
      this.setState({
        sound: ''
      });
    }
  });

  render() {

    return(
      <main id='drum'>
        <div id='drum-machine'>
          <Controls 
              sound={this.state.sound} 
              volume={this.state.volume}
              power={this.state.power} 
              handlerVolume={this.handlerVolume}
              handlerPower={this.handlerPower}
            />
          <DrumMachine
            power={this.state.power}
            volume={this.state.volume}
            handlerDrum={this.handlerDrum}
            />
        </div>
      </main>
    );
  }
}


export default Drum;