import React from 'react';
import * as d3 from "d3";
import "./styles/Visual5.css"
class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.updateGraph = this.updateGraph.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.tmpCeil = 5;
  }
  
  roundColour(t,r) {
    return Math.floor((t+0.00001)/r)*r;
  }

  componentDidUpdate (prevProps) {
    if(this.props !== prevProps) {
      this.updateGraph();
    }
  }
  componentDidMount () {
    this.updateGraph();
  }
  handleMouse = (event) => {
    let target = event.target.nodeName === 'rect'?event.target:event.target.parentNode.parentNode.firstChild;
    let tooltip = document.getElementById('tooltip')
    tooltip.style.display = 'flex';
    tooltip.style.top = event.layerY+'px';
    tooltip.style.left = event.layerX+'px';
    let temp = document.createElement('p');
    temp.textContent = target.dataset.name;
    tooltip.appendChild(temp);
    temp = document.createElement('p');
    temp.textContent = `Category: ${target.dataset.category}`;
    tooltip.appendChild(temp);
    temp = document.createElement('p');
    temp.textContent = `Value: ${target.dataset.value}`;
    tooltip.appendChild(temp);
    tooltip.setAttribute('data-value',target.dataset.value);
    tooltip.style.marginLeft = '-'+Math.ceil(tooltip.offsetWidth/2) + 'px';
    tooltip.style.marginTop = '-'   + parseInt( tooltip.offsetHeight + 20).toString() + 'px';
    target.setAttribute("stroke",'red')
    target.setAttribute("stroke-width",'2')
  }
  handleMouseOut = (event) => {
    let tooltip = document.getElementById('tooltip')
    tooltip.innerHTML = '';
    tooltip.setAttribute('style','display: none');
    tooltip.removeAttribute('data-education');
    let target = event.target.nodeName === 'rect'?event.target:event.target.parentNode.parentNode.firstChild;
    target.removeAttribute('stroke');
  }

  updateGraph = () => {
    const graphCont = document.getElementById('graph');
    while (graphCont.firstChild) {
      graphCont.removeChild(graphCont.lastChild);
    }
    const aspect = 0.5;
    const w = Math.min(1400, window.innerWidth - 20);
    const h = Math.max(Math.floor(w*aspect),700); 
    const lW = w < 600 ? 300 : 600;
    const lNcol = w < 600 ? 3 : 6;
    const data = this.props.data;

    const categories = data.children.map(d => d.name);
    const color = d3.scaleOrdinal(categories, d3.schemeTableau10);

    const root = d3.treemap().tile(d3.treemapSquarify)
    .size([w, h])
    .padding(1)
    .round(true)
    (d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value));

    const svg = d3.select('#graph').append('svg')
      .attr("viewBox", [0, 0, w, h])
      .attr("width", w)
      .attr("height", h)
      .attr("style", "max-width: 100%; height: auto;");

    const leaf = svg.selectAll("g")
      .data(root.leaves())
      .join("g")
        .attr("transform", d => `translate(${d.x0},${d.y0})`);

    
    leaf.on('mouseover',this.handleMouse)
        .on('mouseout',this.handleMouseOut);
    leaf.append("rect")
        .attr("data-name", d => d.data.name)
        .attr("data-category", d => d.data.category)
        .attr("data-value", d => d.data.value)
        .attr("class", 'tile')
        .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
        .attr("width", d => d.x1 - d.x0)
        .attr("height", d => d.y1 - d.y0);
    leaf.append("text").selectAll("tspan")
        .data(d => d.data.name.split(/(?=[A-Z][a-z])|\s+(?=[A-Za-z]{3,})/g))
        .join("tspan")
        .attr("x", 3)
        .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`)
        .text(d => d);

    const legend =d3.select('#graph').append('svg')
    .attr("width", lW)
    .attr('id','legend');
    const legendAll = legend.append('g');
    let legendHeight = 0;
    for (let i in categories) {
      let shiftX = (i % lNcol) * 100;
      let shiftY = Math.floor(i / lNcol) * 20;
      legendHeight = shiftY + 20;
      const legendItem = legendAll.append("g")
        .attr("transform", `translate(${shiftX},${shiftY})`)
        .attr("width",100)
        .attr("height",20);
      legendItem.append('rect')
        .attr('class','legend-item')
        .attr('width',16)
        .attr('height',16)
        .attr('fill',color(categories[i]));
      legendItem.append('text')
        .attr('class','legend-text')
        .attr('x',20)
        .attr('y',12)
        .text(categories[i]);
    }
    legend.attr('height',legendHeight);
  }
  render() {
    return (
      <>
        <div id="title">{this.props.title}</div>
        <div id="description">{this.props.desc}</div>
        <div id="graph"></div>
      </>
      
    );
  }
}

class Visual5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: [],
      error: [],
      data: {}
    };
    this.data = {};
    this.titles = [
      ['Video Game Sales','Top 100 Most Sold Video Games Grouped by Platform'],
      ['Movie Sales','Top 100 Highest Grossing Movies Grouped By Genre'],
      ['Kickstarter Pledges','Top 100 Most Pledged Kickstarter Campaigns Grouped By Category']
    ];
    this.showDataSet = this.showDataSet.bind(this);
  }

  loadData(url,num) {
    let loaded = this.state.loaded;
    fetch(url)
    .then(out => out.json())
    .then(
      (result) =>{
        // console.log(result);
        loaded[num] = true;
        this.data[num] = result;
        if(num === 0) {
          this.setState({
            loaded:loaded,
            title:this.titles[num][0],
            desc:this.titles[num][1],
            data:result
          });
        } else {
          this.setState({
            loaded:loaded
          });
        }
      },
      (error) => {
        loaded[num] = true;
        let errors = this.state.error;
        errors.push(error);
        this.setState({
          loaded: loaded,
          error: errors
        });
      }
    );
  }
 
  showDataSet(event) {
    let setNum = event.target.id.split('-')[1];
    if(this.data[setNum] !== null) {
      this.setState({
        title:this.titles[setNum][0],
        desc:this.titles[setNum][1],
        data:this.data[setNum]
      });
    }
  }

  componentDidMount() {
    let url  = ['https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/video-game-sales-data.json',
                'https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/movie-data.json',
                'https://cdn.freecodecamp.org/testable-projects-fcc/data/tree_map/kickstarter-funding-data.json'];
    let load = new Array(url.length).fill(false);
    this.setState({
      loaded:load
    });
    for(let i in url) {
      this.loadData(url[i],i);
    }
  }

  render() {
    let ld = this.state.loaded.length > 0?this.state.loaded[0]:false;
    if(ld) {
      for(let l of this.state.loaded) {
        ld = ld && l;
      }
    }
    if (!ld) {
      return (
        <main>
          <h1> Please wait some time.... </h1>
        </main>
      );
    } else if(this.state.error.length > 0) {
      let errorStr = ''
      for(let err of this.state.error) {
        errorStr = errorStr + `<div>${err}</div>`
      }
      return (
        <main>
          <h1> Error while loading data: </h1>
          ${errorStr}
        </main>
      );
    } else {
      return (
        <main id="visual-5">
          <ul id="vis-5-nav">
            <li id='dataset-0' onClick={this.showDataSet}>Video Game Data Set</li>
            <li>|</li>
            <li id='dataset-1' onClick={this.showDataSet}>Movies Data Set</li>
            <li>|</li>
            <li id='dataset-2' onClick={this.showDataSet}>Kickstarter Data Set</li>
          </ul>
        <Graph 
          title={this.state.title}
          desc={this.state.desc}
          data={this.state.data}
        />
        <div id='tooltip'></div>
      </main>
      );
    }
  }
}

export default Visual5;