import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'isomorphic-dompurify';
import "./styles/Markdown.css"

function postprocess(html) {
  return DOMPurify.sanitize(html);
}
marked.use({ hooks: { postprocess } });
marked.use({breaks:true});
class Editor extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          content: this.props.content
      }
      this.handleFormChange = this.handleFormChange.bind(this);
  }
  handleFormChange = (event) => {
      const val = event.target.value;
      this.props.handler(val);
      this.setState({
          content: val
      });
  }

  componentDidMount() {
      let t = '# Welcome to Markdown Previewer!\n\n## This is a sub-heading\u2026\n\n### And here\u2019s some other cool stuff:\n\nHeres some code, \` <div></div> \` , between 2 backticks.\n\n    // this is multi-line code:\n\n    function anotherExample(firstLine, lastLine) {\n      if (firstLine \n      }\n    }\n\nYou can also make text \*\*bold\*\*\u2026 whoa! Or \_italic\_. Or\u2026 wait for it\u2026\_\*\*both\*\*\_! And\nfeel free to go crazy ~~crossing stuff out~~.\n\nThere\u2019s also \[links\]\(https\:\/\/www.freecodecamp.org\), and \n> Block Quotes!\n\nAnd if you want to get really crazy, even tables:\n\n  Wild Header | Crazy Header | Another Header?\n  ------------------ \| ----------------- \| --------------------\n Your content can \| be here, and it \| can be here...\n  And here. \| Okay. \| I think we get it.\n\n-   And of course there are lists.\n    -   Some are bulleted.\n        -   With different indentation levels.\n            -   That look like this.\n\n1.  And there are numbered lists too.\n2.  Use just 1s if you want!\n3.  And last but not least, let\u2019s not forget embedded images:\n\n \![Prewiew]\(\.\/images\/markdown.png\)';
      this.setState({
          content: t
      });
      this.props.handler(t);
  }

  render() {
      return(
      <section id="editor-block" className="block">
          <header>
              <h4>Editor</h4>
              <div className='expand'></div>
          </header>
          <textarea id="editor" value={this.state.content} onChange={this.handleFormChange}/>
      </section>
      );
  }
}
class Preview extends React.Component {
  constructor(props) {
      super(props);
      this.state={
          contentHTML: this.props.content
      }
  }
  componentDidUpdate() {
      let prev=document.getElementById('preview');
      prev.innerHTML = this.props.content;
  };

  render() {
      return(
      <section id="preview-block"  className="block">
          <header>
              <h4>Previewer</h4>
              <div className='expand'></div>
          </header>
          <div id="preview">{this.state.contentHTML}</div>
      </section>
      );
  }
}
class Markdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        textContent:'',
        htmlContent:''
    }
  }
  handleFormChange = (val => {
    let html = marked.parse(val);
    this.setState({
      textContent: val,
      htmlContent: html
    });
  }).bind(this);
  render() {
    return (
      <main id='markdown'>
        <Editor 
            content={this.state.textContent} 
            handler={this.handleFormChange}
        />
        <Preview content={this.state.htmlContent}/>
      </main>
    );
  }
}

export default Markdown;