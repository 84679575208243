export default function Projects() {

  let projects = [
    {
      key:0,
      title:'Random Quotes',
      img:'random-quotes.png',
      href:'random_quotes',
    },
    {
      key:5,
      title:'Miner',
      img:'miner.png',
      href:'sapper'
    },
    {
      key:6,
      title:'MarketCRM',
      img:'marketcrm.png',
      href:'marketcrm',
      desc:'HTML + CSS + JavaScript (using only JQuery and DateKeeper) for PHP driven CRM system.',
      target:'_blank'
    },
    {
      key:10,
      title:'Markdown',
      img:'markdown.png',
      href:'markdown'
    },
    {
      key:20,
      title:'Drum',
      img:'drum.png',
      href:'drum'
    },
    {
      key:30,
      title:'Calculator',
      img:'calc.png',
      href:'calc'
    },
    {
      key:40,
      title:'Timer',
      img:'pomodoro.png',
      href:'timer'
    },
    {
      key:51,
      title:'Visual 1',
      img:'visual-1.png',
      href:'visual-1'
    },
    {
      key:52,
      title:'Visual 2',
      img:'visual-2.png',
      href:'visual-2'
    },
    {
      key:53,
      title:'Visual 3',
      img:'visual-3.png',
      href:'visual-3'
    },
    {
      key:54,
      title:'Visual 4',
      img:'visual-4.png',
      href:'visual-4'
    },
    {
      key:55,
      title:'Visual 5',
      img:'visual-5.png',
      href:'visual-5'
    },
  ];

  let gallery = projects.map(project => 
    <figure className="project" key={project.key}>
      <a href={project.href} target={project.target ? project.target : '_self'}>
        <img loading="lazy" alt={project.desc ? project.desc : project.title} title={project.desc && project.desc} src={process.env.PUBLIC_URL + '/images/' + project.img} />
        <figcaption className="project-tile"><p>{project.title}</p></figcaption>
      </a>
    </figure>);
  return(
    <section id="projects">
      <header>Some coding examples</header>
      <div id="projects-gallery">
        {gallery}
      </div>
    </section>
  );
}